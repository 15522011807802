import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

{
  /*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>This is home page !</p>
      </header>
    </div>
  );
}
*/
}

function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch("https://app.nuttycreators.com:5002/mesg")
      //fetch("http://127.0.0.1:5000/mesg")
      //fetch("http://127.0.0.1:5000/mesg")
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error("Flask server is not running.");
        }
      })
      .then((data) => {
        setMessage(data);
        console.log(data);
      })
      .catch((error) => {
        setMessage("Your app is running on ReactJS.");
        console.error(error);
      });
  }, []);

  return (
    <div>
      <h1>{message}</h1>
      <h2>Your app is running on ReactJS.</h2>
    </div>
  );
}

export default App;
